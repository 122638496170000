@import 'normalize.css/normalize.css';

@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import '~@fortawesome/fontawesome-free/scss/regular';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~@fortawesome/fontawesome-free/scss/brands';

@import "~bootstrap/scss/bootstrap-utilities";

$card-border-color: #E2E2E2;
$card-title-color: #000000;

@import "~bootstrap/scss/navbar";
@import "~bootstrap/scss/alert";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/card";
@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/modal";
@import "~bootstrap/scss/close";
@import "~bootstrap/scss/buttons";
@import "~bootstrap-icons";
@import 'cropperjs/dist/cropper.css';

@import "common.css";

@import "front/global.scss";
@import "front/cropper.scss";
@import "front/cookie.scss";
@import "front/alert.scss";
@import "front/form.scss";
@import "front/button.scss";
@import "front/pages/cookies.scss";
@import "front/pages/login.scss";
@import "front/pages/reset_password.scss";
