@import '~bootstrap/scss/bootstrap-utilities';
@import '~bootstrap/scss/mixins';

.offcanvas-lg {
  --bs-offcanvas-zindex: 10000;

  &.offcanvas-bottom {
    height: auto;
    overflow: auto;

    .offcanvas-header {
      border-bottom: 1px solid #E2E2E2;
      font-family: 'abchanel_pb_l';
      font-size: 18px;
      font-weight: 400;
      line-height: 28px;
      letter-spacing: -0.02em;
      text-align: center;

      h5 {
        margin-top: 0;
      }

      .btn-close {
        cursor: pointer;
      }
    }

    .offcanvas-footer {
      padding: 0 1rem 1rem 1rem;
    }
  }
}

@include media-breakpoint-up(lg) {
 .offcanvas-lg {
    background-color: #fff !important;
    max-width: 50%;
    margin: 0 auto;
    bottom: 50%;
    transform: translateY(50%) !important;

    &.hiding {
      visibility: hidden;
    }

    .offcanvas-header {
      display: flex;
    }

    .offcanvas-body {
      padding: 1rem;

      .text {
        text-transform: none;
        font-family: futura, Arial, serif;
        font-size: 0.923rem;
        font-weight: 500;
        line-height: 20px;
        text-align: left;
      }
    }
  }
}

@include media-breakpoint-down(lg) {
  .offcanvas {
    .offcanvas-header {
      justify-content: center;

      .btn-close {
        display: none;
      }
    }

    .offcanvas-footer {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 80px;
      box-shadow: 0px -2px 10px 0px #00000017;
      padding: 1rem;

      .btn {
        min-width: auto;
      }

      .btn-light {
        border: 0;
        box-shadow: 0 1px 2px 0 #0000000D;

        .fa-solid {
          display: inline-block;
        }
      }
    }
  }
}



