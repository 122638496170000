@import "bootstrap/scss/transitions";
@import "bootstrap/scss/accordion";

#cookie-consent {
  scrollbar-color: #000000 transparent;
  scrollbar-width: thin;
  z-index: 1000;
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  max-height: 100%;
  overflow: hidden;
  overflow-y: auto;
  overscroll-behavior: contain;
  background: #FFFFFF;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.3);
  color: #000000;
  transform: translate3d(0, 0, 0);
  transition: transform 0.5s, opacity 0.5s, visibility 0.5s;
  font-family: futura;
  text-align: center;

  .card-title {
    font-size: 28px;
    font-weight: 200;
    margin: 10px;
  }

  .cookie-consent--text {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;

    a {
      color: #000000;
    }
  }

  .cookie-consent--details {
    &-hide {
      color: #000000;
      font-family: abchanel_pb;
      font-size: 11px;
    }
  }

  .accordion-item {

    .accordion-header {

      .form-check {
        width: 100%;
        text-align: left;
        padding: 14px 0 14px 46px;
        display: flex;
        align-items: center;
        margin-top: 0;
        margin-bottom: 0;
        cursor: pointer;
      }

      &.disabled {
        .form-check {
          pointer-events: none;
        }
      }

      &:not(.disabled) {
        &:hover {
          background-color: rgba(0,0,0,0.10);
        }
      }
    }

    .accordion-body {
      background: rgba(0,0,0,.05);

      .form-text {
        font-size: 14px;
        color: #000;
        text-align: left;
      }
    }
  }

  .accordion-button {
    width: auto;

    &:hover {
      background-color: rgba(0,0,0,0.10);
      cursor: pointer;
    }

    &:not(.collapsed) {
      background-color: rgba(0,0,0,0.10);
      color: #000;

      &::after {
        background-image: var(--bs-accordion-btn-icon);
      }
    }

    .form-check-input {
      width: 1.05em;
      height: 1.05em;
      margin-top: 2px;

      &:checked::before {
        width: 0.5em;
        height: 0.5em;
      }
    }
  }

  .cookie-consent--actions {
    .btn-dark {
      height: 50px;
      font-size: 10px;
    }
  }
}

@media (min-width: 40em) {
  #cookie-consent {
    right: 25px;
    bottom: 55px;
    left: auto;
    max-width: 600px;
    max-height: calc(100% - 50px);
  }
}
