.btn {
  --bs-btn-focus-box-shadow: none;
  --bs-btn-border-radius: 0;
  --bs-btn-font-size: 11px;
  --bs-btn-font-family: 'abchanel_pb_l';

  &:not(.btn-lg):not(.btn-sm) {
    --bs-btn-padding-x: 20px;
    --bs-btn-padding-y: 7px;
  }

  &.btn-lg {
    --bs-btn-font-size: 0.938rem;
    --bs-btn-padding-y: 0.74rem;
    font-weight: 400;
  }
}

.btn-dark {
  --bs-btn-bg: #000;
  --bs-btn-border-color: #000;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #000;
  --bs-btn-hover-border-color: #000;

  &.btn-hover:hover {
    --bs-btn-hover-color: #000;
    --bs-btn-hover-bg: #fff;
  }

  &.btn-hover:focus {
    --bs-btn-hover-color: #000;
    --bs-btn-hover-bg: #fff;
  }
}

.btn-light {
  --bs-btn-bg: #fff;
  --bs-btn-border-color: #000;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #fff;
  --bs-btn-hover-border-color: #000;

  &.btn-hover:hover {
    --bs-btn-hover-border-color: #adadad;
  }
}
