#modal-language-selection {
  .btn-label {
    display: inline-block;
    padding: 1em 2em;
    margin: 0.5em 0;
    cursor: pointer;
    color: #000000;
    border-radius: 0.25em;
    background: #FFFFFF;
    transition: 0.3s;
    border: 1px solid #000;
    user-select: none;
    width: 100%;

    &:hover {
      background: #000000;
      color: #FFFFFF;
      box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2), inset 0 -3px 0 rgba(0, 0, 0, 0.32);
    }
  }

  input[type="radio"]:checked + .btn-label {
    background: #000000;
    color: #FFFFFF;

    &:hover {
      background: #000000;
      color: #FFFFFF;
    }
  }

  .form-check {
    padding: 0;
  }

  input[type="radio"] {
    display: none;
  }
}
