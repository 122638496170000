@import '~bootstrap/scss/bootstrap-utilities';
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/grid";

#login {
  .background-video {
    position: fixed;
    width: 100%;
    height: 100%;
    max-width: 100%;
    left: 0;
    z-index: -5;

    img, video {
      display: block;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  .login-title {
    text-align: center;
    padding: 41px 0;

    h1 {
      font-family: 'abchanel_pb_l', sans-serif;
      font-size: 1.938rem;
      font-weight: 400;
      line-height: 56px;
      letter-spacing: -0.02em;
      margin: 0;
      text-align: center;
    }

    h2 {
      font-family: 'futura', serif;
      font-size: 1.125rem;
      font-weight: 500;
      line-height: 1.4;
      letter-spacing: 0.03em;
      text-align: center;
      margin-bottom: 0;
    }
  }

  .login-card {
    margin-bottom: 3rem;

    .card-body {
        display: flex;
        flex-direction: column;
        min-height: 232px;
        padding: 32px;

        .card-title {
          font-family: 'abchanel_pb_l', sans-serif;
          text-transform: uppercase;
          margin-top: 0;
          font-size: 1.563rem;
          font-weight: 400;
          line-height: 32px;
          letter-spacing: -0.02em;
          text-align: center;
          margin-bottom: 16px;
        }

        .card-subtitle {
          font-family: futura, serif;
          font-size: 1rem;
          font-weight: 500;
          line-height: 1.4;
          letter-spacing: 0.03em;
          text-align: center;
          margin-bottom: 40px !important;
        }

        .login-card--actions {

          &-reset a {
            font-family: futura, serif;
            font-size: 1rem;
            font-weight: 500;
            line-height: 1.4;
            letter-spacing: 0.03em;
            color: #808080;
          }

          &-remember {
            padding-left: 0;
            font-size: 1rem;

            label {
              font-family: futura, serif;
            }
          }

          &-submit {
            margin-top: 40px;
            margin-bottom: 40px;

            button {
              width: 100%;

              &:hover {
                --bs-btn-hover-color: #fff;
                --bs-btn-hover-bg: #000;
              }
            }
          }
        }

        hr {
          border: 0;
          width: 100%;
          background-color: #E2E2E2;
          height: 1px;
        }

        .back-login {
          font-family: abchanel_pb, serif;
          text-transform: uppercase;
          font-weight: 200;
          font-size: 10px;
        }
      }

      &--registering {
        text-align: center;
        margin-top: 40px;
        margin-bottom: 15px;

        &-title {
          font-family: 'abchanel_pb_l', sans-serif;
          font-size: 0.938rem;
          font-weight: 400;
          line-height: 16px;
        }

        &-subtitle {
          font-family: 'futura';
          font-size: 1rem;
          font-weight: 500;
          line-height: 21.25px;
          letter-spacing: 0.03em;
          color: #808080;
          margin-top: 16px;
          margin-bottom: 16px;
        }

        &-link {
          font-family: 'abchanel_pb_l';
          font-size: 0.938rem;
          font-weight: 400;
          line-height: 16px;
        }
      }
  }
}

@include media-breakpoint-down(lg) {
  #login {
    .login-title {
      h1 {
        font-size: 1.563rem;
        line-height: 32px;
        letter-spacing: -0.02em;
      }

      h2 {
        font-size: 1rem;
        line-height: 21.25px;
        letter-spacing: 0.03em;
      }
    }

    .login-card {
      margin-bottom: 0;

      .card {
        background-color: transparent;
        border: 0;

        .card-body {
          //--bs-card-color: #ffffff;
          padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);

          .card-title, .card-subtitle {
            display: none;
          }

          /*.form-floating {
            > .form-control {
              color: #fff;
              --bs-border-color: #fff;

              &:hover, &:focus {
                border-color: #fff;
              }
            }

            > label {
              color: #fff;
            }
          }*/

          .login-card--actions {
            gap: 10px;

            &-reset a {
              font-size: 0.813rem;
            }

            &-remember {
              padding-left: 0;
              flex-wrap: wrap;

              label {
                font-size: 0.813rem;
              }
            }

            &-submit {
              margin-bottom: 32px;

             /* button {
                --bs-btn-color: #000;
                --bs-btn-bg: #fff;


                &:hover {
                  --bs-btn-hover-color: #000;
                  --bs-btn-hover-bg: #fff;
                }
              }*/
            }
          }
        }
      }

      &--registering {
        margin-top: 32px;
        /*&-subtitle, &-link a {
          color: #ffffff;
        }*/
      }
    }
  }
}
