.form-label {
  display: block;
  cursor: pointer;
}

*:not(.form-floating) > .show-hide-password-container:not(.form-floating) {
  overflow: hidden;
  display: flex;
  width: 100%;

  .show-hide-password {
    cursor: pointer;
    padding: 0.5rem 0.75rem;
    margin-bottom: 0;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.25;
    color: #495057;
    text-align: center;
    background-color: #e9ecef;
    border: 1px solid rgba(0, 0, 0, .15);
    display: flex;
    align-items: center;
    width: 49px;

    .fa.fa-eye {
      padding-left: 1px;
    }
  }
}

.form-floating {
  font-size: 0.938rem;
  transition: transform 0.3s ease;
  margin-bottom: 1.5rem;

  > label {
    text-transform: uppercase;
    transition: color 0.5s ease, transform 0.3s ease;
    color: #808080 !important;
    top: 0.85rem;
    padding: 0.5rem 0 1rem 0;
    font-size: 0.875rem;
  }

  .form-control, .form-control-plaintext {
    padding-top: 1.625rem !important;
  }

  .form-select {
    padding-top:1rem !important;
  }

  .form-control, .form-control-plaintext, .form-select {
    border-width: 0 0 1px 0;
    text-align: left;
    padding-right: 0.2rem;
    padding-left: 0.2rem;
    padding-bottom: 0;
    border-radius: 0;
    transition: color 0.5s, border-bottom-color 0.5s;
    font-size: 0.875rem;
    height: calc(3rem + var(--bs-border-width) * 2);
    min-height: calc(2.5rem + calc(var(--bs-border-width) * 2));
    background-color: transparent;

    &:valid {
      border-bottom-color: #d9dbdd;
    }

    &:focus, &:not(:placeholder-shown) {
      padding-top: 1.25rem;

      ~ label {
        transform: translateY(-0.85rem) scale(.90);
        color: #808080;
        font-size: 0.625rem;
        padding-top:0 !important;

        &:after {
          inset: 1rem 0.375rem;
          background-color: transparent;
          position: unset !important;
        }
      }
    }

    &:hover {
      border-bottom-color: #000000;

      &:disabled {
        border-bottom-color: #d9dbdd;
        cursor: not-allowed;
      }
    }

    &:disabled {
      color: #a6a6a6;
    }

    &:invalid:focus, &:valid:focus, &.is-valid:focus, &.is-invalid:focus {
      box-shadow: none !important;
    }

    &.is-valid, &.is-invalid {
      border-width: 0 0 1px 0;
    }
  }

  &:hover {
    > label {
      color:#000000;
    }
  }

  &.show-hide-password-container {
    .show-hide-password {
      position: absolute;
      right: 5px;
      top: 13px;
      z-index: 5;
    }
  }
}

input {
  background-color: #fff;
  border: #d7d7d7 solid 1px;
  font-size: 14px;
  padding: 2px;
  font-family: "futura";
  font-weight: 400;

  &.form-control {
    display: block;
    width: 100%;
    border-radius: 0;
    font-weight: 400;
    font-size: 14px;

    &:focus {
      box-shadow: none;
      border-color: #000000;
    }
  }
}

textarea {
  &.form-control {
    font-size: 14px;
    font-family: 'futura';
  }
}

select {
  background-color: #fff;
  border: #d7d7d7 solid 1px;
  font-size: 14px;
  padding: 2px;
  font-family: 'futura';
  font-weight: 400;

  &.form-select {
    display: block;
    width: 100%;
    border-radius: 0;
    font-size: 14px;
    font-weight: 400;

    &:focus {
      box-shadow: none;
      border-color: #000000;
    }
  }
}

/* checkbox */

input[type="checkbox"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #fff;
  font: inherit;
  color: currentColor;
  width: 1.35em;
  height: 1.35em;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: 0.15em;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
  margin: 0 10px 0 0;

  &::before {
    content: "";
    width: 0.85em;
    height: 0.85em;
    -webkit-clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    transform: scale(0);
    transform-origin: bottom left;
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em #000000;
    display: block;
  }


  &:checked::before {
    transform: scale(1);
  }
}

.form-check-input {
  &:checked {
    background-color: #000;
    border-color: #000;

    &:before {
      box-shadow: inset 1em 1em #fff;
    }
  }

  &[type="checkbox"] {
    border-radius: 0.15em;
  }

  &:focus {
    box-shadow: 0 0 0 0.25rem rgba(0, 0, 0, 0.25) !important;
    border: 1px solid #000 !important;
  }
}

input[type="radio"] {

  &:checked {
    background-color: #000000;
    border-color: #000000;
  }

  &:focus {
    box-shadow: none;
  }
}

.form-label {
  word-break: break-all;
}

.needs-validation:not(.was-validated) {
  .invalid-feedback {
    display: none !important;
  }
}

.invalid-feedback {
  font-family: 'futura';
}

.form-select, .form-control {
  &:placeholder-shown {
    text-overflow: ellipsis;
  }
}

.needs-validation:not(.was-validated) {
  div:not(.form-floating) {
    .form-control.is-invalid {
      border: var(--bs-border-width) solid var(--bs-border-color);
      background: none !important;

      &:focus {
        box-shadow: 0 0 0 0.25rem rgba(0, 0, 0, 0.25);
        border-color: #000;
      }
    }

    .is-invalid ~ .invalid-feedback, .is-invalid ~ .invalid-tooltip {
      display: none !important;
    }

    .form-select.is-invalid, .form-select:invalid {
      border: var(--bs-border-width) solid var(--bs-border-color);
      --bs-form-select-bg-icon: none;
      padding-right: 2.25rem;

      &:focus {
        box-shadow: 0 0 0 0.25rem rgba(0, 0, 0, 0.25);
        border-color: #000;
      }
    }
  }

  .form-check-input.is-invalid ~ .form-check-label {
    color: unset;
    border-color: #000;
  }

  .form-check-input {
    &:invalid, &.is-invalid {
      border: var(--bs-border-width) solid var(--bs-border-color);

      &:checked {
        background-color: #000;
        border-color: #000;
      }

      &:focus {
        box-shadow: 0 0 0 0.25rem rgba(0, 0, 0, 0.25) !important;
      }
    }
  }

  .form-floating {
    .form-select, .form-control {
      border-width: 0 0 1px 0 !important;

      &:hover {
        &:not(:disabled) {
          border-color: #000 !important;
        }
      }

      &:invalid, &.is-invalid {
        border-bottom-color: #d9dbdd;
        background-image: var(--bs-form-select-bg-img), var(--bs-form-select-bg-icon, none);
      }
    }

    .form-select {
      &:invalid, &.is-invalid {
        background-image: var(--bs-form-select-bg-img) !important;
      }
    }

    > .form-select ~ label.required, > .form-control ~ label.required {
      &::after {
        content: "*";
      }
    }
  }
}

.was-validated {
  input[type="checkbox"]::before {
    box-shadow: none !important;
  }

  .form-check-input:valid, .form-check-input.is-valid {
    border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color-translucent);

    &:focus {
      box-shadow: 0 0 0 0.25rem rgba(0, 0, 0, 0.25) !important;
    }

    &:checked {
      background-color: #000 !important;
      background-position: right center;
      --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23ffffff%27/%3e%3c/svg%3e");
      border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color-translucent);
    }

    ~ .form-check-label {
      color: #000000;
    }
  }

  div:not(.form-floating) {
    .form-select, .form-control {
      &:valid, &.is-valid {
        border: var(--bs-border-width) solid var(--bs-border-color);

        &:focus {
          box-shadow: 0 0 0 0.25rem rgba(0, 0, 0, 0.25);
          border-color: #000;
        }
      }
    }
  }

  .form-floating {
    .form-select, .form-control {
      &:invalid ~ label, &.is-invalid ~ label {
        color: var(--bs-form-invalid-border-color) !important;
      }

      &:invalid:hover, &.is-invalid:hover, &:invalid, &.is-invalid {
        border-color: var(--bs-form-invalid-border-color) !important;
      }

      &.is-invalid, &.is-valid, &:invalid, &:valid {
        border-width: 0 0 1px 0 !important;
      }

      &.is-valid, &:valid:not(.is-invalid) {
        border-color: #d9dbdd;
        &:hover {
          border-color: #000 !important;
        }
      }

      &:invalid, &.is-invalid {
        ~ .show-hide-password {
          right: 30px;
        }
      }
    }
  }

  .form-control {
    &:valid, &.is-valid {
      background-image: none;
      padding-right: 0.2rem;
    }
  }

  .form-select {
    &:valid, &.is-valid {
      &:not([multiple]) {
        &:not([size]), &[size="1"] {
          --bs-form-select-bg-icon: none
        }
      }
    }

    &:invalid + .select2 .select2-selection {
      border-color: var(--bs-danger);

      &:focus {
        box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25);
      }
    }
  }
}

.form-text.help-text {
  font-family: "futura", Arial, sans-serif;
  font-size: 0.95em;
}

.form-check {
  display: flex;
  align-items: center;
}

.input-group {
  > .form-select[id$="phone_country"] {
    max-width: 130px;
  }
}


/** specific phone block **/

.block-phone {
  .form-select, .form-control {
    min-height: auto;
    background-position: right 0.75rem center !important;
  }

  .form-select {
    padding-top: 1rem !important;
    max-width: 100px;
    margin-right: 15px;
    background-position-y: 26px !important;
    padding-right: 0;
  }

  .input-group {
    margin-top: -15px;
  }
}

.col-form-label {
  font-size: 9px;
  color: #808080;
  margin-top: 25px;
  padding-bottom: 0;
}
