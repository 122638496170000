@import 'bootstrap/scss/mixins';
@import 'bootstrap/scss/offcanvas';
@import 'bootstrap/scss/containers';
@import 'bootstrap/scss/badge';
@import 'modal-language';
@import 'offcanvas';

html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

body {
  margin: 0;
  font-family: abchanel_pb, serif;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}


body.default-background {
  background: #f3f3f3 url('../../medias/page-background-desktop.jpg');
  background-size: cover;

  @include media-breakpoint-down(lg) {
    background-image: none;
    background-color: #fff;
  }
}

a {
  color: #000;
}

hr {
  border: 0;
  background-color: #E2E2E2;
  height: 1px;
}

.isMobile {
  display: none !important;
  @media (max-width: 960px) {
    display: block !important;
  }
}

.isNotMobile {
  display: block;
  @media (max-width: 960px) {
    display: none !important;
  }
}

header {
  nav {
    position: sticky;
    top: 0;
    z-index: 999;
    border-top: 8px solid #000;
    border-bottom: 1px solid #ececec;
    padding-top: 5px;
    padding-bottom: 5px;
    background: #fff;

    .container {
      display: flex;
      flex-wrap: inherit;
      align-items: center;
      justify-content: flex-start !important;

      .navbar-menu {
        #button-menu {
          display: block;
          width: 16px;
          padding: 12px;
          cursor: pointer;
          box-sizing: content-box;

          div {
            border-top: solid 1px #000;
          }

          &:before {
            content: '';
            display: block;
            border-top: solid 1px #000;
            padding-top: 5px;
          }

          &:after {
            content: '';
            display: block;
            border-bottom: solid 1px #000;
            padding-top: 5px;
          }
        }
      }

      .navbar-chapter {
        @media (max-width: 767px) {
          display: none;
        }

        ul {
          list-style: none;
          margin: 0;
          padding: 0;

          li {
            a {
              text-decoration: none;
              font-size: 15px;
              position: relative;

              &.selected {
                font-weight: bold;

                &:after {
                  content: "";
                  display: inline-block;
                  background-color: black;
                  width: 8px;
                  height: 8px;
                  border-radius: 4px;
                  position: absolute;
                  top: 28px;
                  margin: 0 0 0 calc(-50% - 4px);
                }
              }
            }
          }
        }

      }

      .navbar-brand {
        a {
          display: flex;
          align-items: center;

          img {
            height: 17px;
          }
        }

        &.without-menu-mobile {
          padding-left: 80px;
        }
      }

      .navbar-right {
        display: flex;
        margin-left: auto;

        @media (max-width: 767px) {
          top: 4px;
        }

        #button-menu-user {
          padding: 10px;
          cursor: pointer;
          box-sizing: content-box;
          display: flex;
          justify-content: center;
          align-items: center;

          @media (max-width: 960px) {
            padding: 0;
          }

          span {
            margin-right: 6px;
          }

          .avatar {
            cursor: pointer;
            display: block;
            width: 35px;
            height: 35px;
            position: relative;
            border-radius: 50%;
            overflow: hidden;
            text-align: center;
            vertical-align: bottom;
            justify-content: center;

            @media (max-width: 960px) {
              width: 25px;
              height: 25px;
            }

            img {
              max-width: 100%;
            }
          }
        }

        .language-switcher {
          margin-right: 0;
          padding: 0;

          .btn {
            padding: 0;
            border: 0;
          }
        }
      }
    }
  }
}

body {
  .content-login, .content-register {
    .navbar-right {
      margin-right: auto;
    }
  }
}

.language-switcher {
  list-style: none;
  margin-right: 15px;

  #dropdown-language {
    color: #000;
    text-decoration: none;
  }

  a.dropdown-item {
    width: auto;
    font-size: 12px;
    --bs-dropdown-link-active-bg: #000000;
  }
}

.modal-content {
  border-radius: 0;

  .btn-close {
    background: transparent url("data:image/svg+xml,<svg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23FFF%27><path d=%27M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z%27/></svg>") center/1em auto no-repeat;
    opacity: 1;
    cursor: pointer;
  }

  .modal-header {
    border-bottom: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    background: #000;
    color: #FFF;

    h5 {
      color: #FFF;
      font-size: 12px;
      margin-top: 0;
    }

    .btn-close {

    }
  }

  .modal-body {
    font-family: futura, Arial, serif;

    &.left {
      text-align: left;
    }
  }

  .modal-footer {
    border-top: 0;
  }
}

.btn-grey {
  --bs-btn-bg: #000;
  --bs-btn-border-color: #000;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #fff;
  --bs-btn-hover-border-color: #E2E2E2;
  color: #FFF;
  border: none;
}

.btn-light-bis {
  --bs-btn-bg: #fff;
  --bs-btn-border-color: #000;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #fff;
  --bs-btn-hover-border-color: #FFF;
  border: none;
  border-bottom: 1px solid #E2E2E2;
}

.offcanvas {
  .language-switcher {
    margin-right: 15px;
  }
}

main > .container {
  @media (max-width: 960px) {
    margin-bottom: 60px;
  }
}

#canvas-menu {
  .offcanvas-backdrop {
    opacity: 0;
  }

  .offcanvas {
    margin-top: 8px;
    padding-top: 0;
    max-width: 100%;
    @media (max-width: 960px) {
      margin-top: 55px;
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      &.language-switcher {
        margin: 30px 0 0 0;

        li {
          border-bottom: 0;
          padding: 0;
        }
      }

      li {
        border-bottom: 1px solid #000;
        padding: 15px 0 15px 0;

        a {
          color: #000;
          text-decoration: none;

          .next-button {
            position: absolute;
            right: 0;
            width: 40px;
            height: 100%;
            cursor: pointer;
          }
        }
      }
    }
  }
}

main {
  flex-grow: 1;
  padding-bottom: 50px;

  @media (max-width: 960px) {
    padding-bottom: 60px;
  }
}

footer {
  flex-shrink: 0;
  width: 100%;
  min-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  background-color: #000;
  color: #fff;
  @media (max-width: 960px) {
    min-height: auto;

    .links {
      padding: 8px !important;
    }
  }

  @media (min-width: 768px) {
    min-height: 30px;
    z-index: 1000;
    bottom: 0;
    left: 0;
  }

  .links {
    list-style: none;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 0 15px;
    margin: 0;

    li {
      margin-right: 15px;
      margin-top: 12px;
      margin-bottom: 12px;
      width: 100%;
      text-align: center;

      &:last-child {
        margin-top: 12px;
        margin-bottom: 12px;
      }

      a {
        color: #fff;
        text-decoration: none;
        text-transform: uppercase;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

.button {
  margin: 0 auto;
  display: block;
  width: fit-content;
  text-decoration: none;
  @media (max-width: 960px) {
    max-width: 90%;
    font-size: 0.8em;
  }
}

.card {
  border-radius: 0;
}

.content-full-height {
  .container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media (min-width: 360px) {
  header .navbar-right .language-switcher {
    margin-right: 15px;
  }
}

@include media-breakpoint-up(lg) {
  header {
    nav {
      height: 80px;

      .navbar-brand {
        margin-right: 50px;

        a img {
          height: 19px;
        }
      }

      .navbar-right {

        .language-switcher {
          .btn {
            padding: 6px 20px;
            border: #000 solid 1px;
          }
        }
      }

      .navbar-menu {
        display: none;
      }

      #canvas-menu {
        .offcanvas {
          padding-top: 0;
          max-width: 80%;
        }
      }
    }
  }

  footer {
    ul.links {
      li {
        margin-top: 3px;
        margin-bottom: 3px;
        width: auto;
        text-align: left;

        &:last-child {
          margin-top: 3px;
          margin-bottom: 0;
        }
      }
    }
  }
}

.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: wrap;
}

