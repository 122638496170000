.alert {
  border-radius: 0;
  font-family: 'futura';
  font-size: 0.9rem;

  &-success {
    --bs-alert-color: #fff;
    --bs-alert-bg: #309165;
    --bs-alert-border-color: #309165;
  }

  &-danger {
    --bs-alert-color: #fff;
    --bs-alert-bg: #990e0e;
    --bs-alert-border-color: #990e0e;
  }
}
